<template>
    <div class="pb-5">
        <div class="mt-2 mb-3 container-header">
            <!-- <div class="d-flex align-items-center justify-content-between mb-1">
                <div>
                    <div class="">
                        <p class="m-0">Mostrando 1 a 10 de 14 registros</p>
                    </div>
                </div>
                <div></div>
            </div> -->

            <div class="container-body-header">
                <div class="d-flex align-items-center">
                    <p
                        class="page-btn"
                        :class="[pageActive == 1 ? 'disabled' : null]"
                        @click="changePage(pageIn - 1)"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="20" />
                    </p>
                    <div class="container-btns">
                        <p
                            @click="changePage(num)"
                            class="page-btn"
                            :class="[pageActive == num ? 'active' : null]"
                            v-for="(num, index) in pages"
                            :key="index"
                        >
                            {{ num }}
                        </p>
                    </div>
                    <p
                        class="page-btn"
                        :class="[pageActive == totalPages ? 'disabled' : null]"
                        @click="changePage(pageIn + 1)"
                    >
                        <feather-icon icon="ChevronRightIcon" size="20" />
                    </p>
                </div>
                <div
                    class="d-flex align-items-center justify-content-center mt-2 mt-md-0"
                >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar colaborador"
                        v-model="campo"
                        v-on:keyup.enter="keyEnter"
                    />
                    <CreateUsersGuest @init="init" />
                </div>
            </div>
        </div>
        <div class="m-auto card content-table">
            <div class="container-table-coliseum header-table">
                <p>#</p>
                <p>Usuario</p>
                <p>Teléfono</p>
                <p>Roles</p>
                <p>Estado</p>
                <p>Creado por</p>
                <p>Acciones</p>
            </div>
            <div
                class="container-table-coliseum body-table"
                v-for="(item, index) in data"
                :key="index"
            >
                <div
                    class="d-flex align-items-center justify-content-center body-index"
                >
                    <p>{{ index + 1 }}</p>
                </div>

                <div class="body-name body-cell">
                    <p>
                        {{ item.name }}
                        {{ item.lastname ? item.lastname : null }}
                    </p>
                    <span v-if="item.email" class="email-user">{{
                        item.email
                    }}</span>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <p v-if="item.phone">{{ item.phone }}</p>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <div class="container-tournament">
                        <p
                            class="tournament-link"
                            v-for="(rol, key) in item.roles"
                            :key="key"
                        >
                            {{ rol.name }}
                        </p>
                    </div>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <v-select
                        :id="`${item.id}-id-select`"
                        v-model="item.status"
                        :options="statusArray"
                        :clearable="false"
                        transition=""
                        placeholder="Elija el ejemplar"
                        @input="toggleUserStatus(item.status, item.id)"
                        :reduce="option => option.id"
                    >
                        <template #option="{ label, color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>

                        <template #selected-option="{ label,color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>
                    </v-select>
                </div>

                <div class="body-name body-index">
                    <p>{{ item.created_by.name }}</p>
                    <span>{{ item.created_by.created_at | myGlobalDay }}</span>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <feather-icon
                        class="icon cursor-pointer"
                        icon="EditIcon"
                        :size="width < 600 ? '18' : '24'"
                        @click="selectUser(item)"
                    />
                    <feather-icon
                        @click="deleteUser(item.id)"
                        class="icon text-danger"
                        icon="TrashIcon"
                        :size="width < 600 ? '18' : '24'"
                    />
                </div>
            </div>
            <div class="text-center" v-if="data.length == 0">
                <p class="text-center mb-2 mt-2 font-medium-1">
                    Sin datos de organizadores
                </p>
            </div>
        </div>
        <EditUsersGuest
            v-if="selectEditUserGuest"
            @init="init"
            :item="selectEditUserGuest"
            @resetSelectEditUserGuest="resetSelectEditUserGuest"
        />
    </div>
</template>

<script>
import CreateUsersGuest from "./components/CreateUsersGuest.vue";
import usersGuestService from "./user-guest.service";
import vSelect from "vue-select";
import EditUsersGuest from "./components/EditUsersGuest.vue";

export default {
    name: "ListOrganizersBrain",
    components: { CreateUsersGuest, vSelect, EditUsersGuest },
    data() {
        return {
            data: [],
            width: this.$store.state["app"].windowWidth,
            pageIn: 1,
            perPageIn: 10,
            campo: null,
            pages: [],
            pageActive: 1,
            totalPages: null,
            openModal: false,
            statusArray: [
                {
                    id: 1,
                    label: "Activo",
                    color: "#28c66f"
                },
                {
                    id: 0,
                    label: "Inactivo",
                    color: "#ea5455"
                }
            ],
            selectEditUserGuest: null
        };
    },

    async mounted() {
        this.init();
    },
    methods: {
        resetSelectEditUserGuest() {
            this.selectEditUserGuest = null;
        },
        selectUser(user) {
            this.selectEditUserGuest = user;
        },
        async init() {
            const {
                data: { data, last_page, current_page }
            } = await usersGuestService.getUserGuest({
                status: 0,
                campo: this.campo,
                pageIn: this.pageIn,
                perPageIn: this.perPageIn
            });

            // this.pages = this.createPages(last_page);
            // this.pageActive = current_page;
            // this.totalPages = last_page;

            // this.data = data.map(organizer => {
            //     return {
            //         ...organizer,
            //         tournaments: JSON.parse(organizer.tournaments)
            //     };
            // });
            this.pages = this.createPages(last_page);
            this.pageActive = current_page;
            this.totalPages = last_page;
            this.data = data.map(item => {
                return {
                    ...item,
                    created_by: JSON.parse(item.created_by),
                    roles: JSON.parse(item.roles)
                        ? JSON.parse(item.roles)
                        : [],
                    status: parseInt(item.status)
                };
            });
            console.log("users: ", this.data);
            this.selectEditUserGuest = null;
        },
        async toggleUserStatus(status, id) {
            const {
                data: { ok, msg }
            } = await usersGuestService.updateToggleUser({
                status,
                id
            });
            if (ok) {
                await this.showSuccessToast(
                    msg,
                    "success",
                    "top-left",
                    "Usuario invitado",
                    "CheckIcon"
                );
                this.openModal = false;
            }
            await this.init();
        },
        createPages(num) {
            let pages = [];
            for (let i = 1; i <= num; i++) {
                pages.push(i);
            }
            return pages;
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.pageIn = page;
                this.init();
            }
        },
        keyEnter() {
            this.pageIn = 1;
            this.init();
        },
        refreshModal() {
            this.init();
        },
        async deleteUser(id) {
            const swal = await this.showConfirmSwal();
            if (swal.isConfirmed) {
                const {
                    data: { ok, msg }
                } = await usersGuestService.deleteUserById({
                    id
                });
                if (ok) {
                    await this.showSuccessToast(
                        msg,
                        "success",
                        "top-left",
                        "Usuario invitado",
                        "CheckIcon"
                    );
                    this.openModal = false;
                }
                this.init();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content-table {
    min-width: 100%;
    background: #fff;

    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    @media screen and (min-width: 1000px) {
        overflow-x: visible;
        overflow-y: visible;
    }
    .container-table-coliseum {
        min-width: 800px;
        padding: 0.5rem 1rem;
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 4fr 4fr 4fr 4fr;
        text-align: center;
        @media screen and (min-width: 600px) {
            padding: 1rem 2rem;
        }

        p {
            margin: 0;
            font-size: 12px;
            transition: 0.3s all ease-in-out;
            @media screen and (min-width: 600px) {
                font-size: 14px;
            }
        }
        .icon {
            //color: red;
            margin: 0 0.2rem;
            cursor: pointer;
        }
    }
    .header-table {
        background: #f3f2f7;
        text-align: center;
        p {
            font-weight: 600;
        }
    }
    .body-table {
        border-bottom: 0.5px solid #e6e6e6;
        &:last-child {
            border-bottom: none;
        }
        .body-index {
            min-width: 30.88px;
        }
        .body-cell {
            min-width: 123.52px;
            p {
                word-wrap: break-word;
            }
            .badge {
                width: 80%;
                @media screen and (min-width: 600px) {
                    width: 50% !important;
                }
            }
        }
        .body-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .email-user {
                background: #7367f0;
                border-radius: 4px;
                padding: 2px 8px;
                color: #fff;
                font-weight: bold;
                font-size: 10px;
                @media screen and (min-width: 600px) {
                    font-size: 12px;
                }
            }
        }
    }
}
.container-tournament {
    display: flex;
    flex-direction: column;
    width: 60%;
    p {
        font-size: 12px !important;
        background: #fafafa;
        width: 100%;
        margin-bottom: 0.4rem !important;
        padding: 0.2rem 0.3rem;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    .tournament-link {
        color: #7367f0 !important;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
            font-weight: bold;
        }
    }
}
//btn-agregar-organizer
.container-header {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    transition: 0.1s all ease-in-out;
    @media screen and (min-width: 600px) {
        padding: 2rem;
    }
    .page-btn {
        height: 32px;
        width: 32px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        border-radius: 32px;
        cursor: pointer;
        background: #f3f2f7;
    }
    .container-btns {
        margin: 0 0.4rem;
        width: auto;
        background: #f3f2f7;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        transition: 0.1s all ease-in-out;
        .active {
            background: #7367f0;
            color: #fff;
        }
    }
    .disabled {
        color: #d5d5d5;
    }
}
.dark-layout {
    .content-table {
        background: #283046;
        .header-table {
            background: #343d55;
        }
        .body-table {
            border-bottom-color: #3b4253;
        }
    }
    .container-header {
        background: #283046;
        .page-btn {
            background: #242b3d;
        }
        .container-btns {
            background: #242b3d;
            .active {
                background: #7367f0;
                color: #fff;
            }
        }
    }
}
.dark-layout {
    .container-tournament {
        p {
            background: #242b3f;
        }
    }
}
.circle {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 4px;
}
.container-body-header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
