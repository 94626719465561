<template>
    <div>
        <b-modal
            v-if="user"
            size="xl"
            @ok.prevent="editUserGuest"
            title="Agregar participante"
            v-model="openModal"
            @hidden="closeModal"
        >
            <validation-observer tag="b-row" ref="form">
                <b-col cols="4">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Nombre del usuario invitado"
                            label-for="name"
                        >
                            <b-form-input
                                placeholder="Nombre del usuario"
                                v-model="user.name"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider rules="" v-slot="{ errors, valid }">
                        <b-form-group
                            label="Apellido del usuario invitado"
                            label-for="lastname"
                        >
                            <b-form-input
                                placeholder="Apellido del usuario"
                                v-model="user.lastname"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider rules="" v-slot="{ errors, valid }">
                        <b-form-group
                            label="Teléfono del usuario invitado"
                            label-for="phone"
                        >
                            <b-form-input
                                v-mask="'###-###-###'"
                                placeholder="Teléfono del usuario"
                                v-model="user.phone"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <b-form-group
                        label="Correo del usuario invitado"
                        label-for="email"
                    >
                        <b-form-input
                            placeholder="Correo del usuario"
                            v-model="user.email"
                        />
                        <!-- <span style="color: red">{{ errors[0] }}</span> -->
                    </b-form-group>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider
                        rules=""
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Roles del usuario"
                            label-for="roles"
                        >
                            <v-select
                                multiple
                                v-model="user.roles"
                                :options="rolesArray"
                                :clearable="false"
                                label="name"
                                :reduce="option => option.id"
                                transition=""
                                placeholder="Elija los roles"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import usersGuestService from "../user-guest.service";
import vSelect from "vue-select";

export default {
    name: "EditUsersGuest",
    props: ["item"],
    components: { vSelect },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    data() {
        return {
            width: this.$store.state["app"].windowWidth,
            openModal: false,
            name: "",
            errorText: false,
            valid: false,
            user: {
                name: null,
                lastname: null,
                phone: null,
                email: null,
                roles: [],
                user_id: null,
                id: null
            },
            rolesArray: []
        };
    },
    async mounted() {
        this.user.name = this.item.name;
        this.user.lastname = this.item.lastname;
        this.user.phone = this.item.phone;
        this.user.email = this.item.email;
        this.user.roles = this.item.roles.map(item => {
            return item.role_id;
        });
        this.user.id = this.item.id;
        this.openModal = true;

        await this.getRolesUsersGuest();
    },

    methods: {
        closeModal() {
            this.openModal = false;
            this.user.name = null;
            this.user.lastname = null;
            this.user.phone = null;
            this.user.email = null;
            this.user.roles = [];
            this.$emit("resetSelectEditUserGuest");
            // this.$emit("init");
        },
        async getRolesUsersGuest() {
            const { data } = await usersGuestService.getRolesByIds({
                ids: "1,2,7"
            });
            this.rolesArray = data;
        },
        async editUserGuest() {
            const success = await this.$refs.form.validate();
            if (success) {
                const {
                    data: { ok, msg }
                } = await usersGuestService.updateUserById({ ...this.user });
                if (ok) {
                    await this.showSuccessToast(
                        msg,
                        "success",
                        "top-left",
                        "Usuario invitado actualizado",
                        "CheckIcon"
                    );
                    this.$emit("init");
                    this.openModal = false;
                } else {
                    await this.showSuccessToast(
                        msg,
                        "warning    ",
                        "top-left",
                        "Información",
                        "InfoIcon"
                    );
                }
            } else {
                await this.showSuccessToast(
                    "Debe completar los campos para poder guardar el usuario invitado.",
                    "warning    ",
                    "top-left",
                    "Información",
                    "InfoIcon"
                );
            }
        }
    },
    watch: {}
};
</script>

<style lang="scss" scope>
.btn-agregar-users-guest {
    width: 220px;
    margin-left: 0.5rem;
}
.error-input {
    margin-bottom: 0;
    color: red;
    font-size: 12px;
    margin-top: 2px;
}
</style>
