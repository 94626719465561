import axios from "@/axios";

class UsersGuestService {
    async getRolesByIds(body) {
        const data = await axios.post(`/api/users/get-roles-users`, body)
        return data;
    }
    async saveUserGuest(body) {
        const data = await axios.post(`/api/users/save-user-guest`, body)
        return data;
    }
    async getUserGuest(body) {
        const data = await axios.post(`/api/users/get-users-guest`, body)
        return data;
    }
    async updateToggleUser(body) {
        const data = await axios.put(`/api/users/update-toggle-users`, body)
        return data;
    }
    async deleteUserById(body) {
        const data = await axios.put(`/api/users/delete-user-by-id`, body)
        return data;
    }
    async updateUserById(body) {
        const data = await axios.put(`/api/users/update-user-by-id`, body)
        return data;
    }
    //users/update-user-by-id
    //users/get-users-guest
    //users/update-toggle-users
    //users/delete-user-by-id
}
export default new UsersGuestService()