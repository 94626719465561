var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"btn btn-primary btn-agregar-users-guest",on:{"click":function($event){_vm.openModal = true}}},[_vm._v(" Agregar colaborador ")]),_c('b-modal',{attrs:{"size":"xl","title":"Agregar colaborador invitado"},on:{"ok":function($event){$event.preventDefault();return _vm.saveUserGuest($event)}},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('validation-observer',{ref:"form",attrs:{"tag":"b-row"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Nombre del colaborador invitado","label-for":"name"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre del colaborador","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Apellido del colaborador invitado","label-for":"lastname"}},[_c('b-form-input',{attrs:{"placeholder":"Apellido del colaborador"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Teléfono del colaborador invitado","label-for":"phone"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-###'),expression:"'###-###-###'"}],attrs:{"placeholder":"Teléfono del colaborador"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Correo del colaborador invitado","label-for":"email"}},[_c('b-form-input',{attrs:{"placeholder":"Correo del colaborador"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Roles del colaborador invitado","label-for":"roles"}},[_c('v-select',{attrs:{"multiple":"","options":_vm.rolesArray,"clearable":false,"label":"name","reduce":function (option) { return option.id; },"transition":"","placeholder":"Elija los roles","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }