<template>
    <div>
        <button
            class="btn btn-primary btn-agregar-users-guest"
            @click="openModal = true"
        >
            Agregar colaborador
        </button>
        <b-modal
            size="xl"
            @ok.prevent="saveUserGuest"
            title="Agregar colaborador invitado"
            v-model="openModal"
        >
            <validation-observer tag="b-row" ref="form">
                <b-col cols="4">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Nombre del colaborador invitado"
                            label-for="name"
                        >
                            <b-form-input
                                placeholder="Nombre del colaborador"
                                v-model="user.name"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider rules="" v-slot="{ errors, valid }">
                        <b-form-group
                            label="Apellido del colaborador invitado"
                            label-for="lastname"
                        >
                            <b-form-input
                                placeholder="Apellido del colaborador"
                                v-model="user.lastname"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider rules="" v-slot="{ errors, valid }">
                        <b-form-group
                            label="Teléfono del colaborador invitado"
                            label-for="phone"
                        >
                            <b-form-input
                                v-mask="'###-###-###'"
                                placeholder="Teléfono del colaborador"
                                v-model="user.phone"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <b-form-group
                        label="Correo del colaborador invitado"
                        label-for="email"
                    >
                        <b-form-input
                            placeholder="Correo del colaborador"
                            v-model="user.email"
                        />
                        <!-- <span style="color: red">{{ errors[0] }}</span> -->
                    </b-form-group>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider
                        rules=""
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Roles del colaborador invitado"
                            label-for="roles"
                        >
                            <v-select
                                multiple
                                v-model="user.roles"
                                :options="rolesArray"
                                :clearable="false"
                                label="name"
                                :reduce="option => option.id"
                                transition=""
                                placeholder="Elija los roles"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import usersGuestService from "../user-guest.service";
import vSelect from "vue-select";

export default {
    name: "CreateUsersGuest",
    components: { vSelect },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    data() {
        return {
            width: this.$store.state["app"].windowWidth,
            openModal: false,
            name: "",
            errorText: false,
            valid: false,
            user: {
                name: null,
                lastname: null,
                phone: null,
                email: null,
                roles: [],
                user_id: null
            },
            rolesArray: []
        };
    },
    async mounted() {
        await this.getRolesUsersGuest();
        this.user.user_id = this.currentUser.user_id;
    },

    methods: {
        async getRolesUsersGuest() {
            const { data } = await usersGuestService.getRolesByIds({
                ids: "1,2,7"
            });
            this.rolesArray = data;
        },
        async saveUserGuest() {
            const success = await this.$refs.form.validate();
            if (success) {
                const {
                    data: { ok, msg }
                } = await usersGuestService.saveUserGuest({ ...this.user });
                if (ok) {
                    await this.showSuccessToast(
                        msg,
                        "success",
                        "top-left",
                        "Usuario invitado creado",
                        "CheckIcon"
                    );
                    this.$emit("init");
                    this.openModal = false;
                    this.user.name = null;
                    this.user.lastname = null;
                    this.user.phone = null;
                    this.user.email = null;
                    this.user.roles = [];
                } else {
                    await this.showSuccessToast(
                        msg,
                        "warning    ",
                        "top-left",
                        "Información",
                        "InfoIcon"
                    );
                }
            } else {
                await this.showSuccessToast(
                    "Debe completar los campos para poder guardar el usuario invitado.",
                    "warning    ",
                    "top-left",
                    "Información",
                    "InfoIcon"
                );
            }
        }
    },
    watch: {}
};
</script>

<style lang="scss" scope>
.btn-agregar-users-guest {
    width: 140px !important;
    margin-left: 0.5rem;
    font-size: 10px;
    padding-left: 2px !important;
    padding-right: 2px !important;
    @media screen and (min-width: 600px) {
        width: 220px !important;
        font-size: 14px;
    }
}
.error-input {
    margin-bottom: 0;
    color: red;
    font-size: 12px;
    margin-top: 2px;
}
</style>
